import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	errorsFetched: false,
	errorCodes: [],
	loading: false,
};

const getErrorsStart = (state) => {
	return updateObject(state, { loading: true });
};

const getErrorsSuccess = (state, action) => {
	const errorCodes = action.data;
	return updateObject(state, {
		data: action.data,
		errorsFetched: true,
		loading: false,
		errorCodes,
	});
};

const clearErrors = () => {
	return initialState;
};

const errors = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ERRORS_START:
			return getErrorsStart(state, action);
		case actionTypes.GET_ERRORS_SUCCESS:
			return getErrorsSuccess(state, action);
		case actionTypes.CLEAR_ERRORS:
			return clearErrors(state, action);
		default:
			return state;
	}
};

export default errors;
