import ReactGA from 'react-ga';
import { coreInstance as axios, api, messages } from 'settings';
import { logger, toast } from 'helpers';
import * as actionTypes from './actionTypes';
import { loadingOn, loadingOff } from 'store/actions';
import i18next from 'i18next';

const getProvidersStart = () => {
	return {
		type: actionTypes.GET_PROVIDERS_START,
	};
};

const getProvidersSuccess = (data) => {
	return {
		type: actionTypes.GET_PROVIDERS_SUCCESS,
		data: data,
	};
};

export const getProviders = (options) => {
	if (options) {
		options.sortedBy = 'asc';
		options.orderBy = 'name';
	} else {
		options = {
			sortedBy: 'asc',
			orderBy: 'name',
		};
	}
	return (dispatch, getState) => {
		dispatch(getProvidersStart());
		const state = getState();
		const { data: providersData = [], providersFetched = false } =
			state?.providers || {};
		if (!providersFetched) {
			axios
				.get(api.providers.index, { params: options })
				.then((response) => {
					logger('[Action] Get Providers');

					dispatch(getProvidersSuccess(response.data.data));
				})
				.catch(() => {
					logger('[Action] Get Providers fail');
				});
		} else {
			return providersData;
		}
	};
};

const addProviderSuccess = (data) => {
	ReactGA.event({
		category: 'Providers and Users',
		action: 'Create',
		label: i18next.t('create_provider'),
	});

	toast.success(messages.providerCreated);

	return {
		type: actionTypes.ADD_PROVIDER,
		data: data,
	};
};

export const addProvider = (data, success, error) => {
	var form_data = new FormData();

	for (var key in data) {
		form_data.append(key, data[key]);
	}
	return (dispatch) => {
		axios
			.post(api.providers.index, form_data)
			.then((response) => {
				logger('[Action] Add Provider');
				dispatch(addProviderSuccess(response.data.data));

				success && success(response.data.data);
			})
			.catch((err) => {
				logger('[Action] Add Provider fail');

				error && error(err?.response?.data?.errors);
			});
	};
};

const blockProviderSuccess = (id) => {
	return {
		type: actionTypes.BLOCK_PROVIDER,
		id: id,
	};
};

export const blockProvider = (id, success, error) => {
	return (dispatch) => {
		axios
			.post(`${api.providers.toggle_status}/${id}`)
			.then((response) => {
				logger('[Action] Block Provider');
				dispatch(blockProviderSuccess(id));
				success && success(response);
				if (response.data.data.is_blocked === true) {
					toast.success(messages.providerBlocked);
				} else {
					toast.success(messages.providerUnblocked);
				}
			})
			.catch(() => {
				logger('[Action] Block Provider fail');
				error && error();
			});
	};
};

const deleteProviderSuccess = (id) => {
	toast.success(messages.providerDeleted);

	return {
		type: actionTypes.DELETE_PROVIDER,
		id: id,
	};
};

export const deleteProvider = (id, success, error) => {
	return (dispatch) => {
		axios
			.delete(api.providers.index + `/${id}`)
			.then(() => {
				logger('[Action] Delete Provider');

				dispatch(deleteProviderSuccess(id));

				success && success();
			})
			.catch(() => {
				logger('[Action] Delete Provider fail');

				error && error();
			});
	};
};

const updateProviderSuccess = (data, id, isComplete) => {
	if (isComplete) {
		toast.success(messages.pinCompleted);
	} else {
		toast.success(messages.providerSaved);
	}

	return {
		type: actionTypes.UPDATE_PROVIDER,
		data: data,
		id: id,
	};
};

export const updateProvider = (args = {}) => {
	const { data, id, success, error } = args;
	var form_data = new FormData();

	for (var key in data) {
		form_data.append(key, data[key]);
	}

	return (dispatch) => {
		dispatch(loadingOn());

		axios
			.post(api.providers.index + `/${id}`, form_data)
			.then((response) => {
				logger('[Action] Update Provider');

				dispatch(
					updateProviderSuccess(response.data.data, id, data.code_part_3),
				);
				dispatch(loadingOff());

				success && success();
			})
			.catch((err) => {
				logger('[Action] Update Provider fail');

				dispatch(loadingOff());

				error && error(err?.response?.data?.errors);
			});
	};
};
