import React, { Fragment } from 'react';
import { createSelector } from 'reselect';
import { unknownProvider } from '../../../containers/UsersProviders/Configs/constants';
import {
	Update,
	LocationOne,
	Discount,
	Service,
	Pair,
	Replace,
	Billing,
	Advertisements,
	Reminder,
	DataLog,
} from 'components/Icons';
import i18next from 'i18next';

const getRoles = (state) => {
	// const { is_inogen } = state.user.data.provider;

	return state.roles.data;
};
const getTypesWithIcon = (state) => {
	const { data } = state.pushNotifications.types;

	return (
		data &&
		data.map((el) => {
			const obj = { ...el };

			let icon = '';

			switch (el.code) {
				case 'update':
					icon = <Update />;
					break;
				case 'location':
					icon = <LocationOne />;
					break;
				case 'discount':
				case 'promotion':
					icon = <Discount />;
					break;
				case 'service':
					icon = <Service />;
					break;
				case 'pair':
					icon = <Pair />;
					break;
				case 'replace':
					icon = <Replace />;
					break;
				case 'billing':
					icon = <Billing />;
					break;
				case 'advertisement':
					icon = <Advertisements />;
					break;
				case 'reminder':
					icon = <Reminder />;
					break;
				case 'data_transfer':
					icon = <DataLog />;
					break;
				default:
					break;
			}

			obj.label = (
				<Fragment>
					{icon}
					<span>{i18next.t(obj.label)}</span>
				</Fragment>
			);

			return obj;
		})
	);
};
const getProviders = (state) =>
	state.providers.data.filter(
		(el) =>
			el.id !== unknownProvider.ID &&
			el.name.toLowerCase() !== unknownProvider.NAME.toLowerCase(),
	);
const getErrors = (state) => {
	const obj = {};
	const data = [];
	let i = 1;

	state.errors.data.forEach((el) => {
		if (obj[el.code_string]) {
			return obj[el.code_string].push(el);
		}

		obj[el.code_string] = [el];
	});

	for (let key in obj) {
		const codes = [];
		const concentrator_model_ids = [];
		obj[key].forEach((el) => {
			codes.push(el.code);
		});
		obj[key].forEach((el) => {
			concentrator_model_ids.push(el.concentrator_model_id);
		});
		data.push({
			id: i,
			code: codes,
			code_string: obj[key][0].code_string,
			description: obj[key][0].description,
			concentrator_model_id: concentrator_model_ids,
			error_id: obj[key][0].id,
		});

		i++;
	}

	data.push({
		id: 'column_life',
		code: null,
		code_string: 'column_life',
		description: i18next.t('colum_life_less_then_17%'),
	});

	data.push({
		id: 'no_errors',
		code: null,
		code_string: 'no_errors',
		description: 'No errors',
	});

	return data;
};
const getTypes = (state) => state.pushNotifications.types.data;
const getFirmwares = (state) => state.pushNotifications.firmwares.data;
const getModels = (state) => state.models.data;
const getProvidersNotifiable = (state) => state.providersNotifiableData.data;
const getFirmwaresNotifiable = (state) => state.firmwaresNotifiableData.data;
const getHistory = (state) => state.pushNotifications.history.data;
const getRows = (state) => state.pushNotifications.history.rows;
const getHasMoreHistory = (state) =>
	state.pushNotifications.history.hasMoreData;
const getTableLoading = (state) => state.pushNotifications.history.loading;
const getRequestParams = (state) =>
	state.pushNotifications.history.requestParams;
const getCurrentUser = (state) => state.user.data;
const getUser = (state) => state.user.data;

export const makeGetAllpushNotificationsState = () =>
	createSelector(
		getProviders,
		getRoles,
		getFirmwares,
		getModels,
		getProvidersNotifiable,
		getFirmwaresNotifiable,
		getErrors,
		getTypes,
		getHistory,
		getRows,
		getHasMoreHistory,
		getTableLoading,
		getRequestParams,
		getCurrentUser,
		getTypesWithIcon,
		getUser,
		(
			providersData,
			rolesData,
			firmwaresData,
			modelsData,
			providersNotifiabledata,
			firmwaresNotifiabledata,
			errorsData,
			typesData,
			historyData,
			historyRows,
			hasMorehistoryData,
			tableLoading,
			requestParams,
			currentUserData,
			typesWithIconData,
			user,
		) => ({
			providersData,
			rolesData,
			firmwaresData,
			modelsData,
			providersNotifiabledata,
			firmwaresNotifiabledata,
			errorsData,
			typesData,
			historyData,
			historyRows,
			hasMorehistoryData,
			tableLoading,
			requestParams,
			currentUserData,
			typesWithIconData,
			user,
		}),
	);
